<template>
  <div>
    <div class="flex">
      <div class="flex">
        <p class="font-headers font-medium text-xl text-grey-medium-2">
          Job Role Details /
        </p>
        <p class="font-headers font-bold text-xl text-grey-dark-2 ml-1">
          General Information
        </p>
      </div>
      <div class="flex flex-grow justify-end">
        <Button v-if="isReadOnly" text="Back" type="primary-white" size="medium" :iconLeft="arrowLeft" rotateArrow
          @click.native="goBack" />
        <Button v-if="!isReadOnly" text="Cancel" type="secondary" size="medium" data-testid="next-button" class="mr-1"
          @click.native="goBack" />
        <Button v-if="!isReadOnly" text="Next" type="primary" :iconRight="arrowRight" size="medium"
          data-testid="next-button" :disabled="$v.$invalid" @click.native="goNext" />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-6 mb-4">
      <div class="max-w-3/5">
        <p class="font-headers font-bold text-mdl text-grey-light mb-2">
          Basic Details
        </p>
        <div class="px-2">
          <p class="text-mdh text-grey-dark-1 mb-3">
            Adding job titles will optimize the setup assessment process for
            customers by mapping employees to a coresponding job role. Job Role
            names are need to be unique for this Assessment Product.
          </p>
          <InputField class="mb-2" label="Job Role Name" :disabled="isReadOnly" :onChange="(value) => (name = value)"
            :defaultValue="name" type="text" />
          <TextArea class="mb-2" label="Description" :disabled="isReadOnly" :onChange="(value) => (description = value)"
            :defaultValue="description" />
          <p class="mb-1 text-mdh text-grey-dark-2">
            Add as many values as you need. Separate values with a comma.
          </p>
          <MultiTagInput label="Job Titles (Optional)" :disabled="isReadOnly"
            :placeholder="isReadOnly ? '' : placeholderText" :initialValues="jobTitleValues" />
          <!--  -->
        </div>
      </div>
    </div>
    <div v-if="isEditMode" class="bg-white px-2 pt-3 pb-4 rounded shadow mt-2">
      <p class="text-grey-light font-bold font-headers text-mdl">Status</p>
      <div class="pt-1.5 px-2">
        <p class="text-grey-dark-1 text-mdh">
          Deactivating a user role will not longer enable the company admin to
          use it when setting up an assessment.
        </p>
        <div class="flex mt-2 justify-between items-center">
          <p class="text-md" :class="status ? 'text-green' : 'text-red'">
            {{ status ? "Active" : "Inactive" }}
          </p>
          <button v-if="!isReadOnly" @click="toggleJobRole" class="text-md font-semibold" :class="status
              ? 'text-red'
              : 'text-sm-2 font-medium text-blue-dark-1 px-2 py-1 bg-blue-light-1 rounded'
            ">
            {{ status ? "Deactivate Role" : "Activate Role" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import arrowRight from "@/assets/img/icons/arrow-right-white.svg";
import arrowLeft from "@/assets/img/icons/arrow-right.svg";

import InputField from "../../../components/InputField/InputField.vue";
import TextArea from "../../../components/TextArea/index.vue";
import MultiTagInput from "../../../components/MultiTagInput/index.vue";

export default {
  name: "JobRoleCreate",
  components: { InputField, TextArea, MultiTagInput },
  data: () => ({ arrowRight, arrowLeft }),
  validations: {
    name: { required },
    description: { required },
  },
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      jobRole: (state) => state.products.jobRole,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    placeholderText() {
      if (this.jobTitleValues.length) return "";
      return "e.g. Junior Buyers, Mid-level Buyers etc.";
    },
    name: {
      get() {
        return this.jobRole.name;
      },
      set(value) {
        this.setJobRoleName({ name: value });
      },
    },
    status: {
      get() {
        return this.jobRole.isActive;
      },
      set() {
        this.jobRole.isActive = !this.jobRole.isActive;
      },
    },
    description: {
      get() {
        return this.jobRole.description;
      },
      set(value) {
        this.setJobRoleDescription({ description: value });
      },
    },
    jobTitleValues: {
      get() {
        return this.jobRole.jobTitles;
      },
    },
    isEditMode() {
      return !!this.jobRole.id;
    },
    backRoute() {
      if (this.isReadOnly) {
        return `/products/${this.productDetails.productId}/dashboard`;
      }
      if (this.jobRole.id) {
        return `/products/${this.productDetails.productId}/dashboard`;
      }
      return `/products/${this.productDetails.productId}/job-roles`;
    }
  },
  methods: {
    ...mapActions({
      createJobRole: "products/createJobRole",
      editJobRole: "products/editJobRole",
      setJobRoleName: "products/setJobRoleName",
      setJobRoleDescription: "products/setJobRoleDescription",
      changeJobRoleStatus: "products/changeJobRoleStatus",
      setJobTitles: "products/setJobTitles",
      openModal: "modals/openModal"
    }),
    goBack() {
      return this.$router.push(this.backRoute);
    },
    async goNext() {
      const payload = {
        name: this.name,
        description: this.description,
        jobTitles: this.jobTitleValues.join(","),
      };
      if (this.isEditMode) {
        await this.editJobRole({
          id: this.jobRole.id,
          ...payload,
        }).then(() => this.$router.push("benchmarks"));
      } else {
        await this.createJobRole({
          surveyId: this.productDetails.id,
          ...payload,
        }).then(() => this.$router.push("benchmarks"));
      }
    },
    toggleJobRole() {
      if (this.jobRole.isActive) {
        this.openModal({
          modal: "deactivateJobRoleModal",
          props: { id: this.jobRole.id },
        });
      } else
        this.changeJobRoleStatus({
          id: this.jobRole.id,
          isActive: true,
        });
    }
  },
};
</script>
